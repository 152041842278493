import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import $ from 'jquery';

// import Typography from '@material-ui/core/Typography';
// import Button from '@material-ui/core/Button';

const styles = {
    indicators: {
        '& >li': {
            width: 12,
            height: 12,
            borderRadius: '50%',
            marginLeft: 8,
            marginRight: 8,
        }
    },
    carouselImage: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'center'
    },
    applyLink: {
        position: 'absolute',
        top: '60%',
        left: '50%',
        right: 0,
        bottom: '32%',
        width: 200,
        transform: 'translateX(-50%)'
    },

    applyButton: {
        color: '#8bc831',
        background: '#ffffff',
        fontSize: 32,
        borderRadius: 34,
        padding: '0 32px',
        '&:hover': {
            color: '#8bc831',
            background: '#ffffff',
        },
    }
};

const images = [
    {
        src: '/bg_image1.png',
        alt: '/bg_image1.png',
    },
    {
        src: '/轮播图片-02.png',
        alt: '/bg_image1.png'
    },
    {
        src: '/轮播图片-03.png',
        alt: '/bg_image1.png'
    },
    {
        src: '/轮播图片-04.png',
        alt: '/bg_image1.png'
    },
];

class BGCarousel extends React.Component {

    componentDidMount() {
        $("#myCarousel").carousel();
    }

    handleClick = (index) => {
        $("#myCarousel").carousel(index);
    };

    render() {
        const { classes } = this.props;
        return (
            <div style={{ position: 'relative', }}>

                <div id="myCarousel" className="carousel slide"
                     style={{ height: 'calc(100vh - 50px)', marginTop: 50, }}>

                    <ul className={classnames("carousel-indicators", classes.indicators)}>
                        {images.map((img, index) => {
                            return <li key={index}
                                       className={classnames({ 'active': 0 === index })}
                                       onClick={() => this.handleClick(index)}
                            />
                        })}
                    </ul>

                    <div className="carousel-inner" style={{ height: '100%' }}>
                        {images.map((img, index) => {
                            return <div key={index} style={{ height: '100%' }}
                                        className={classnames("carousel-item", { 'active': 0 === index })}>
                                <img src={img.src} alt={img.alt}
                                     className={classes.carouselImage}
                                     style={{}} />
                                {index === 0 && <a href="#contactUs" className={classes.applyLink}>
                                   <span className='text-hide'>apply</span>
                                </a>}

                            </div>
                        })}
                    </div>
                </div>
            </div>

        )
    }
}

export default withStyles(styles)(BGCarousel);