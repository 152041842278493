import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = {
    root: {
        borderRadius: 16,
        paddingTop: 300,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center,center',
        overflow: 'hidden',
        width: "100%",
        boxShadow:'1px 1px 5px 1px rgba(0,0,0,0.1), 0px 0px 3px -1px rgba(0,0,0,.2)'

    },
    contentContainer: {
        backgroundColor: '#f0f0f0',
        display: 'flex',
        flexDirection: 'column',
        height: 100,
        alignItems: 'center',
        textAlign:'center',
        padding: '0 12px 8px'
    },
    title: {
        color: '#8bc831',
        paddingTop: 14
    },
    content: {
        fontSize: 14
    }

};

class Card extends React.Component {

    render() {
        const { classes, src, alt, title, content } = this.props;
        return (
            <div className={classes.root} style={{
                backgroundImage: `url(${src})`
            }}>
                <span className='text-hide'>{alt}</span>
                <div className={classes.contentContainer}>
                    <h5 className={classes.title}>{title}</h5>
                    {content.split('\n').map((str, index) => {
                        return <p key={index} className='content'>{str}</p>
                    })}


                </div>

            </div>
        )
    }
}

export default withStyles(styles)(Card)