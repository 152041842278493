import React from 'react';
import { withStyles } from '@material-ui/core/styles';
// import Paper from '@material-ui/core/Paper';


const courseLevels = [
    {
        src: '/icons-01.png',
        alt: '初級課程',
        title: '初級課程',
        content: 'STEM课程初步学习，感受美\n国文化。提升学员行业知识，\n拓宽国际视野'
    }, {
        src: '/icons-02.png',
        alt: '中級課程',
        title: '中級課程',
        content: '沉浸式学习，进一步走入stem课程，巩固知识提升编程能力。'
    }, {
        src: '/icons-03.png',
        alt: '高級課程',
        title: '高級課程',
        content: '对编程作品颁发专业认证，\n优秀作品推荐参加美国竞赛'
    },
];
const styles = {
    cardContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '34px -40px 10px',
        '@media (max-width: 670px)': {
            margin: '34px 0 10px',
        },
        '@media (max-width: 600px)': {
            flexDirection: 'column',
            margin: '34px 0 10px',
        },
    },
    card: {
        width: 224,
        position: 'relative',
        boxShadow: '1px 1px 5px 1px rgba(0,0,0,0.1), 0px 0px 3px -1px rgba(0,0,0,.2)',
        background: '#ffffff',
        borderRadius: 20,
        '& .step': {
            position: 'absolute',
            top: 6,
            left: 6
        },
        marginBottom: 12
    },
    cardImage: {
        display: 'flex',
        justifyContent: 'center',
        padding: '30px 0',
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: 119,
        padding: '0 10px',
        textAlign: 'center',
        '& > h6': {
            marginBottom: 16
        },
        '& > p': {
            lineHeight: 1.7
        }
    },

};

class Courses extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <div id='courses' style={{ marginTop: -60, paddingTop: '100px', marginBottom: 40 }}>
                <h2 className='section-title' style={{ marginTop: 24 }}>课程设置</h2>
                <section style={{ maxWidth: 648, padding: '0 16px', margin: 'auto' }}>
                    <h4 className='section-subtitle' style={{ marginTop: 45 }}>阶段式课程设置</h4>
                    <p className='content text-indent' style={{ marginTop: 26 }}>
                        您的孩子将在硅谷资深IT工程师及世界级高校名师的共同指导下，通过SV SUMMIT CAMP初级 编程课程，逐步提升对计算机以及前沿科学的浓厚兴趣，提升编程能力。让孩子在科技及计算机领域
                        达到远超同龄人的领悟力，眼界及能力。</p>
                    <p className='content text-indent'>
                        在完成专业定制化的初级培训课程后，优秀毕业生会被选送参加中高级训练班，旨在帮助学员充分准备国际奥林匹克竞赛，为孩子的留美求学之路提供超常规的竞争优势。
                    </p>

                    <div className={classes.cardContainer}>
                        {courseLevels.map((l, index) => {
                            return <div key={index} className={classes.card}>
                                {/*<i className='step'>{index + 1}</i>*/}
                                <div className={classes.cardImage}>
                                    <img src={l.src} alt={l.alt} height='100' />
                                </div>
                                <div className={classes.cardContent}>
                                    <h6 className='section-subtitle2'>{l.title}</h6>
                                    {l.content.split('\n').map((str, index) => {
                                        return <p key={index} className='content'>{str}</p>
                                    })}
                                </div>
                            </div>
                        })}

                    </div>
                    <p className='caption' style={{ textAlign: 'center' }}>注：中、高级课程将通过考试后获准报名</p>
                </section>
            </div>
        )
    }
}

export default withStyles(styles)(Courses);