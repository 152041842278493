import React from 'react';

import { withStyles } from '@material-ui/core/styles';

const styles = {
    contact: {
        display: 'flex',
        alignItems: 'flex-start',
        marginBottom: 20,
        '& > img': {
            width: 18
        },
        '& > h5': {
            margin: '0 40px 0 20px'
        },
        '& > div': {
            marginTop: -4
        }
    },
    flexContainer: {
        display: 'flex',
        '@media (max-width: 600px)': {
            flexDirection: 'column',
        }
    }
};

class ContactUs extends React.Component {

    render() {

        const { classes } = this.props;

        return (
            <div>
                <div style={{ maxWidth: 640, margin: 'auto', padding: '0 12px' }}>
                    <h2 className='section-title' style={{ marginTop: 144 }}>关于我们</h2>

                    <div style={{ display: 'flex', justifyContent: 'center', margin: '34px 0 22px' }}>
                        <img src="/logo5.png" alt="硅谷之巅" height='48' />
                    </div>
                    <p className='content text-indent'>
                        SV Summit Camp 是总部位于美国硅谷的国际专业教育连锁品牌, 旨在启蒙未来人才, 培养世界名校精英。
                    </p>
                    <p className='content text-indent'>
                        深耕科技教育行业, 紧跟硅谷以及世界前沿科技的发展趋势, 为广 大青少年学生提供全方位, 高品质精英教育服务。
                    </p>
                    <p className='content text-indent'>
                        由来自美国顶级名校及世界一流企业的专家, 博士, 教授提供丰富 多样的课程教学与实践活动。提升实际动手能力及创新思维。
                    </p>
                    <p className='content text-indent'>
                        SV Summit Camp是您值得信赖的一流国际教育品牌。
                    </p>
                </div>

                <div style={{ maxWidth: 640, margin: 'auto', padding: '0 12px' }}>

                    <h2 id='contactUs' className='section-title' style={{ marginTop: 74 }}>联系我们</h2>

                    <div className={classes.flexContainer} style={{ alignItems: 'center', margin: '40px 0 30px' }}>
                        <div style={{ flex: 1 }}>
                            <div className={classes.contact}>
                                <img src="/00_icon3-01.png" alt="phone" />
                                <h5 className='section-subtitle2'>垂询热线</h5>
                                <div>
                                    <p className='content'>(+01) 408 832 1132</p>
                                    <p className='content'>(+86) 177 6420 8283</p>
                                </div>
                            </div>

                            <div className={classes.contact}>
                                <img src="/00_icon3-02.png" alt="location" />
                                <h5 className='section-subtitle2'>联系地址</h5>
                                <div>
                                    <p className='content'>3120 Scott Blvd, Santa Clara, </p>
                                    <p className='content'>CA 95054 USA</p>
                                </div>
                            </div>

                            <div className={classes.contact}>
                                <img src="/00_icon3-03.png" alt="wechat" />
                                <h5 className='section-subtitle2'>报名方式</h5>
                                <div>
                                    <p className='content'>详情请咨询我们的微信公众号；</p>
                                    <p className='content'>或参阅我们的官方网站</p>
                                </div>
                            </div>

                        </div>
                        <img src="/qr-code2.png" width='130' alt="contact us"
                             style={{ margin: '0 20px 40px 0' }} />

                    </div>
                </div>

            </div>

        )
    }
}

export default withStyles(styles)(ContactUs)