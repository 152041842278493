import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
// import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';

const styles = {
    root: {
        flexGrow: 1,
    },
    nav: {
        fontWeight: 400,
        // transition: 'all 0.3s ease-in-out',
        marginLeft: 12,
        padding: '5px 13px 4px',
        borderRadius: 8,
        whiteSpace: 'nowrap',
        fontFamily: '"Roboto", "Helvetica", "Arial", "Microsoft Yahei", "微软雅黑", sans-serif',
        '&.active': {
            background: '#8bc831',
            color: '#ffffff'
        },
        '&:hover': {
            background: '#8bc831',
            color: '#ffffff'
        },
    }
};


class Header extends React.Component {
    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <AppBar color="inherit" >
                    <Toolbar id='navbar' className='container'>
                        <Hidden smUp>
                            <div style={{ flex: 1 }} />
                        </Hidden>

                        <a href="#">
                            <img src="/logo5.png" alt="硅谷之巅" height='36' />
                        </a>
                        <div style={{ flex: 1 }} />

                        <Hidden xsDown>
                            <Button className={classnames('nav-link', classes.nav)} href='#aboutUs' disableRipple>
                                选择我们
                            </Button>
                            <Button className={classnames('nav-link', classes.nav)}
                                    href='#courses' disableRipple>
                                课程设置
                            </Button>
                            <Button className={classnames('nav-link', classes.nav)} href='#timeLine' disableRipple>
                                日程安排
                            </Button>
                            <Button className={classnames('nav-link', classes.nav)} href='#contactUs' disableRipple>
                                联系我们
                            </Button>
                        </Hidden>
                    </Toolbar>
                </AppBar>
            </div>
        )
    }
}

export default withStyles(styles)(Header)