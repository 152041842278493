import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Swiper from 'react-id-swiper';
import { Pagination, Navigation, Autoplay, Mousewheel } from 'swiper/dist/js/swiper.esm'


import Card from './Card';

const styles = {
    logos: {
        display: 'flex',
        justifyContent: 'center',
        '& >img': {
            margin: 9
        },
        alignItems: 'center',
        // transform: 'scale(.66)'
    },
    flexContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        margin: '20px 0 24px',
    },

    sectionContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    }
};
const logos = [
    {
        src: '/tutorlogo-01.png',
        alt: 'stanford university'
    }, {
        src: '/tutorlogo-02.png',
        alt: 'berkeley'
    }, {
        src: '/tutorlogo-03.png',
        alt: 'facebook'
    }, {
        src: '/tutorlogo-04.png',
        alt: 'intel'
    }, {
        src: '/tutorlogo-05.png',
        alt: 'apple'
    }, {
        src: '/tutorlogo-06.png',
        alt: 'google'
    },];
const ads = [
    {
        src: '/Apics1.png',
        alt: '',
        title: '顶级高效及业界名师推荐',
        content: '权威认证证书，优秀学员提供名校名企推荐信'
    }, {
        src: '/Apics2.png',
        alt: '',
        title: '核心专业定制课程',
        content: 'STEM优势课程，通过对计算机能力的扎实培训，\n帮助青少年提高逻辑能力，了解硅谷前沿科技'
    }, {
        src: '/Apics3.png',
        alt: '',
        title: '专业启蒙轻松学',
        content: '寓教于乐课程设置，轻松学习专业知识，\n培养青少年对前沿科学的浓厚兴趣'
    }, {
        src: '/Apics4.png',
        alt: '',
        title: '沉浸式体验科技氛围',
        content: '通过参观硅谷一流企业及美国宇航局,\n让学员亲身体验世界科技中心硅谷的魅力'
    }, {
        src: '/Apics5.png',
        alt: '',
        title: '风暴式创新方法训练',
        content: '充分启发孩子编程兴趣, 培养有逻辑的理性创新思考'
    }, {
        src: '/Apics6.png',
        alt: '',
        title: '体验美式英语口语教学',
        content: '通过沉浸式的语言学习, 提高孩子日常会话及学术沟通能力'
    }];
const params = {
    modules: [Pagination, Navigation, Autoplay, Mousewheel],
    slidesPerView: 'auto',
    spaceBetween: 10,
    autoplay: {
        delay: 3500,
        disableOnInteraction: false
    },
    loop: true,
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
    centeredSlides: true,
    mousewheel: {
        forceToAxis: true,
        invert: true
    },

    // navigation: {
    //     nextEl: '.swiper-button-next',
    //     prevEl: '.swiper-button-prev'
    // }
};

class AboutUs extends React.Component {

    render() {
        const { classes } = this.props;
        return (
            <div id="aboutUs" style={{ minHeight: '100vh', marginTop: '-60px', paddingTop: '100px' }}>
                <h2 className='section-title' style={{ marginTop: 34 }}>选择我们</h2>
                <section className={classes.sectionContainer}>
                    <h4 className='section-subtitle'
                        style={{ margin: '12px 0 24px' }}
                    >我们的名师资源</h4>
                    <p className='content'>
                        斯坦福大学与加州伯克利分校等世界一流大学博士及教授</p>
                    <p className='content'>
                        来自谷歌，Facebook等硅谷顶级公司的资深IT工程师</p>
                    <p className='content'>
                        参观谷歌，苹果，因特尔等世界级公司及美国航空航天局
                    </p>
                    <div className={classes.flexContainer}>
                        <div className={classes.logos}>
                            {logos.slice(0, 3).map((logo, index) => {
                                return <img key={index} height={31}
                                            src={logo.src} alt={logo.alt} />
                            })}
                        </div>
                        <div className={classes.logos}>
                            {logos.slice(3).map((logo, index) => {
                                return <img key={index} height={31}
                                            src={logo.src} alt={logo.alt} />
                            })}
                        </div>
                    </div>


                </section>

                <section className={classes.sectionContainer} style={{
                    paddingTop: 40,
                    marginBottom: 30
                }}>
                    <h4 className='section-subtitle'>
                        我们的六大优势
                    </h4>
                    <p className='caption'>向右滑动以查看</p>

                    <Swiper {...params}>
                        {ads.map((ad, index) => {

                            return <div key={index} style={{ maxWidth: 556, width: '95vw', padding: 4 }}>
                                <Card  {...ad} />
                            </div>
                        })}
                    </Swiper>
                </section>
            </div>
        )
    }
}

export default withStyles(styles)(AboutUs)