import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import $ from 'jquery';
import 'swiper/dist/css/swiper.min.css';
import './App.css';


import BGCarousel from './components/BGCarousel';
import Header from './components/Header';
import AboutUs from './components/AboutUs';
import Courses from './components/Courses';
import TimeLine from './components/TimeLine';
import ContactUs from './components/ContactUs';

const styles = {
    bg: {
        backgroundImage: `url(/lightBG6.png)`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        width: '100%'
    },
};

class App extends Component {

    componentDidMount() {
        $('body').scrollspy({ target: "#navbar", offset: 0 });
        // $("#navbar a").on('click', function (event) {
        //     if (this.hash !== "") {
        //         event.preventDefault();
        //         let hash = this.hash;
        //         $('html, body').animate({
        //             scrollTop: $(hash).offset().top
        //         }, 300, function () {
        //             window.location.hash = hash;
        //         });
        //     }
        // });
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Header />
                <BGCarousel />
                <AboutUs />
                <div className={classes.bg}>
                    <Courses />
                    <TimeLine />
                    <ContactUs />
                </div>

                <footer style={{ height: 60, width: '100%', background: '#1a0e64' }}>

                </footer>
            </div>
        );
    }
}

export default withStyles(styles)(App);
