import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

// import DayTimeLine from './DayTimeLine';
import Hidden from '@material-ui/core/Hidden';

const styles = {
    timeLine: {
        display: 'flex',
        paddingTop: 24,
        position: 'relative',
        minHeight: 90,
        '& > h5': {
            minWidth: 66,
            marginTop: 3
        },
        '& p': {
            // height: 22,
            // whiteSpace: 'pre',
            // '@media (max-width: 600px)': {
            //     whiteSpace: 'normal'
            // }
        },
        '&:not(:last-child)::after': {
            backgroundColor: '#efefef',
            bottom: -5,
            content: '""',
            display: 'block',
            height: 1,
            left: 0,
            position: 'absolute',
            // transform: 'translate(-50 %, 0)',
            width: 250
        }
    },
    timeLineContainer: {
        zIndex: 10,
        position: 'relative',
        borderRadius: 0,
        display: 'flex',
        '@media (max-width: 600px)': {
            flexDirection: 'column',
            paddingBottom: 20,
            '& > :first-child': {
                position: 'relative',
                '&::after': {
                    backgroundColor: '#efefef',
                    bottom: -5,
                    content: '""',
                    display: 'block',
                    height: 1,
                    left: 30,
                    position: 'absolute',
                    // transform: 'translate(-50 %, 0)',
                    width: 250
                }
            }
        }
    },
    flexContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    tab: {
        color: 'rgba(0,0,0,.54)',
        cursor: 'pointer',
        textAlign: 'center',
        width: 211,
        margin: '0 4px',
        height: 50,
        lineHeight: '50px',
        borderRadius: 16,
        '&.active': {
            background: '#8bc831',
            color: '#ffffff'
        },
        background: '#ffffff',
        boxShadow: '1px 1px 5px 1px rgba(0,0,0,0.1), 0px 0px 3px -1px rgba(0,0,0,.2)'
    },
    topicContainer: {
        display: 'flex',
        marginTop: 30,
        justifyContent: 'space-around',
        alignItems: 'center',
        '@media (max-width: 600px)': {
            flexDirection: 'column'
        },
    },
    topic: {
        width: 284,
        '& $title': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: '10px 0 14px',
            '& > h6': {
                margin: 0,
                height: 20
            }
        },
        '& p': {
            lineHeight: 1.8,
        },
    },
    title: {},
    shadow: {
        boxShadow: '1px 1px 5px 1px rgba(0,0,0,0.1), 0px 0px 3px -1px rgba(0,0,0,.2)',
        background: '#ffffff'
    },
    font3: {
        fontFamily: 'dengxian, "Roboto", "Helvetica", "Arial", "Microsoft Yahei", "微软雅黑", sans-serif',
        textAlign: 'center',
        '& .content':{
            lineHeight:'1.8'
        }
    }

};

const timeLines = [
    {
        title: '第1天',
        content: '- 飞往旧金山国际机场, 接机及入住酒店\n- 学员破冰交流, 及安全培训\n \n '
    },
    {
        title: '第2天',
        content: '- 开营仪式 - 阮巨城教授讲话\n- 编程课1课时\n- 斯坦福大学D-School参访\n- 斯坦福艺术博物馆参访'
    },
    {
        title: '第3天',
        content: '- 编程课2课时\n- 英特尔博物馆参访\n \n '
    },
    {
        title: '第4天',
        content: '- 编程课程3课时\n- 机动安排\n \n '
    },
    {
        title: '第5天',
        content: '- 加州大学伯克利分校参访\n- 加州大学伯克利分校校内课程\n \n '
    },
    {
        title: '第6天',
        content: '- 旧金山市区参访\n- 旧金山艺术博物馆\n- 九曲花街，渔人码头\n '
    },
    {
        title: '第7天',
        content: '- 编程课程3课时\n- 学术及日常英语会话训练1\n \n '
    },
    {
        title: '第8天',
        content: '- 编程课2课时\n- 学术及日常英语会话训练2\n- 硅谷的创新与文化介绍\n '
    },
    {
        title: '第9天',
        content: '- 编程课2课时\n- 谷歌公司园区参访\n- 美国国家航空航天局参访\n '
    },
    {
        title: '第10天',
        content: '- 编程课3课时\n- 全国青少年信息学奥林匹克竞赛\n  (NOI)介绍\n- 美国历史与文化'
    },
    {
        title: '第11天',
        content: '- 小组最终成果展示\n- 证书颁发与结营仪式\n- Facebook参访\n '
    },
    {
        title: '第12天',
        content: '- 参访加州大学圣克鲁兹分校\n- 冲浪圣地圣克鲁兹小镇探访\n \n '
    },
    {
        title: '第13天',
        content: '- 一号公路\n- 卡梅尔小镇\n- 壮丽十七英里\n '
    },
    {
        title: '第14天',
        content: '- 返回湾区营地\n- 告别晚宴 Farewell Party\n \n '
    },

];
const timeLines2 = [
    {
        title: '第1天',
        content: '- 飞往旧金山国际机场, 接机及入住酒店\n- 学员破冰交流, 安全培训, 课程分组\n '
    },
    {
        title: '第2天',
        content: '- 开营仪式，学习小组分组\n- 编程课4课时\n- 作业辅导2课时'
    },
    {
        title: '第3天',
        content: '- 编程课4课时\n- 作业辅导2课时\n '
    },
    {
        title: '第4天 - 休息日',
        content: ' \n \n '
    },
    {
        title: '第5天',
        content: '- 参观访问斯坦福大学\n- 参观访问斯坦福艺术博物馆\n '
    },
    {
        title: '第6天',
        content: '- 编程课4课时\n- 作业辅导2课时\n '
    },
    {
        title: '第7天',
        content: '- 编程课4课时\n- 作业辅导2课时\n '
    },
    {
        title: '第8天 - 休息日',
        content: ' \n \n '
    },
    {
        title: '第9天',
        content: '- 编程课4课时\n- 作业辅导2课时\n '
    },
    {
        title: '第10天',
        content: '- 参观访问NASA\n- 参观访问谷歌园区\n '
    },
    {
        title: '第11天',
        content: '- 编程课4课时\n- 作业辅导2课时\n '
    },
    {
        title: '第12天 - 休息日',
        content: ' \n \n '
    },
    {
        title: '第13天',
        content: '- 编程课4课时\n- 作业辅导2课时\n '
    },
    {
        title: '第14天',
        content: '- 编程课4课时\n- 作业辅导2课时\n '
    },

    {
        title: '第15天',
        content: '- 参访旧金山艺术博物馆\n- 参访加州大学伯克利分校\n '
    },
    {
        title: '第16天 - 休息日',
        content: ' \n \n '
    },
    {
        title: '第17天',
        content: '- 编程课4课时\n- 作业辅导2课时\n '
    },
    {
        title: '第18天',
        content: '- 编程课4课时\n- 作业辅导2课时\n '
    },
    {
        title: '第19天',
        content: '- 编程课4课时\n- 作业辅导2课时\n '
    },
    {
        title: '第20天 - 休息日',
        content: ' \n \n '
    },
    {
        title: '第21天',
        content: '- 参观访问Facebook\n- 参观访问加州大学圣克鲁兹分校\n '
    },
    {
        title: '第22天',
        content: '- 编程课4课时\n- 作业辅导2课时\n '
    },
    {
        title: '第23天',
        content: '- 编程课4课时\n- 作业辅导2课时\n '
    },
    {
        title: '第24天 - 休息日',
        content: ' \n \n '
    },
    {
        title: '第25天',
        content: '- 编程课4课时\n- 作业辅导2课时\n '
    },
    {
        title: '第26天',
        content: '- 结营仪式，颁发证书\n- 小组作业展示\n- 前往一号公路'
    },
    {
        title: '第27天',
        content: '- 返回湾区营地\n- 告别晚宴 Farewell Party\n '
    }
];

class TimeLine extends React.Component {
    state = {
        tab: 0
    };

    handleTabClick = (tab) => {
        this.setState({ tab })

    };

    render() {
        const { classes } = this.props;
        return (
            <div id='timeLine' style={{ minHeight: '75vw', marginTop: '-60px', padding: '100px 12px 0' }}>
                <h2 className='section-title' style={{ marginTop: 24 }}>日程安排</h2>
                <p className='caption' style={{ textAlign: 'center', margin: '22px 0 40px' }}>
                    点击课程以查看相应日程安排
                </p>
                {/*<p className='content text-indent'*/}
                {/*style={{ maxWidth: 660, margin: 'auto', marginTop: 46, }}>*/}
                {/*学员们会在硅谷资深工程师以及专业的游戏设计师帮助下，通过*/}
                {/*为期两周的编程训练，以小组合作的方式最终完成自己的游戏作品。*/}
                {/*</p>*/}
                {/*<section>*/}
                {/*<h4 className='section-subtitle' style={{ marginTop: 24 }}>每日作息表</h4>*/}
                {/*<div style={{ margin: ' 30px auto 70px', }}>*/}
                {/*<DayTimeLine />*/}
                {/*</div>*/}
                {/*</section>*/}

                <div className={classnames(classes.flexContainer, 'container')}>
                    <div onClick={() => this.handleTabClick(0)}
                         className={classnames(classes.tab, { 'active': this.state.tab === 0 })}
                    >初級課程
                    </div>

                    <div onClick={() => this.handleTabClick(1)}
                         className={classnames(classes.tab, { 'active': this.state.tab === 1 })}
                    >中級課程
                    </div>

                    <div onClick={() => this.handleTabClick(2)}
                         className={classnames(classes.tab, { 'active': this.state.tab === 2 })}
                    >高級課程
                    </div>

                </div>
                {this.state.tab === 0 && <div>
                    <p className='content text-indent'
                       style={{ maxWidth: 660, padding: '0 12px', margin: 'auto', marginTop: 52, }}>
                        学员们会在硅谷资深工程师以及专业的游戏设计师帮助下，通过为期两周的编程训练，以小组合作的方式最终完成自己的游戏作品</p>

                    <section style={{ maxWidth: 760, margin: 'auto', marginTop: 56, }}>
                        <h4 className='section-subtitle'>五大特色主题</h4>
                        <div className={classes.topicContainer}>
                            <div>
                                <div className={classes.topic}>
                                    <div className={classes.title}>
                                        <h6 className='section-subtitle2'>主题一&nbsp;&nbsp;硅谷编程训练营</h6>
                                        <img src='/icon2_1.png' alt='主题一' height='20' />
                                    </div>
                                    <p className='content'>- 数据结构与基础</p>
                                    <p className='content'>- 计算机原理初步</p>
                                    <p className='content'>- 编程语言初步，以C#为例</p>
                                    <p className='content'>- Unity平台编程开发过程介绍</p>
                                    <p className='content'>- 基于Unity平台的交互程序设计</p>
                                    <p className='content'>- NOI(全国青少年信息学奥林匹克竞赛)介绍</p>
                                    <hr />
                                </div>
                                <div className={classes.topic} style={{ marginTop: 30 }}>
                                    <div className={classes.title}>
                                        <h6 className='section-subtitle2'>主题二&nbsp;&nbsp;名校学术体验</h6>
                                        <img src='/icon2_2.png' alt='主题二' height='20' />
                                    </div>
                                    <p className='content'>- 斯坦福大学体验课程 (Stanford University)</p>
                                    <p className='content'>&nbsp;&nbsp;&nbsp;斯坦福博士或教授编程训练营量身定制课程</p>
                                    <p className='content'>- 参访加州大学伯克利分校 (UC Berkeley)</p>
                                    <p className='content'>&nbsp;&nbsp;&nbsp;伯克利博士或教授编程训练营量身定制课程</p>
                                    <hr />
                                </div>
                            </div>

                            <div>
                                <div className={classes.topic}>

                                    <div className={classes.title}>
                                        <h6 className='section-subtitle2'>主题三&nbsp;&nbsp;科学探索之旅</h6>
                                        <img src='/icon2_3.png' alt='主题三' height='20' style={{ marginRight: 8 }} />
                                    </div>
                                    <p className='content'>- 参访美国航空航天局</p>
                                    <p className='content'>- 参访英特尔科学博物馆</p>
                                    <p className='content'>- 参访谷歌园区</p>
                                    <p className='content'>- 参访Facebook园区</p>
                                    <hr style={{ margin: '12px 0 20px' }} />
                                </div>

                                <div className={classes.topic}>
                                    <div className={classes.title}>
                                        <h6 className='section-subtitle2'>主题四&nbsp;&nbsp;沉浸式语言学习</h6>
                                        <img src='/icon2_4.png' alt='主题四' height='20' />
                                    </div>
                                    <p className='content'>- 英语常用语与对话学习</p>
                                    <p className='content'>- 经典英文电影对白配音</p>
                                    <hr style={{ margin: '12px 0 20px' }} />
                                </div>

                                <div className={classes.topic}>
                                    <div className={classes.title}>
                                        <h6 className='section-subtitle2'>主题五&nbsp;&nbsp;人文社会系列</h6>
                                        <img src='/icon2_5.png' alt='主题五' height='20' />
                                    </div>
                                    <p className='content'>- 美国历史与文化</p>
                                    <p className='content'>- 美国名校及专业介绍</p>
                                    <hr style={{ marginTop: '12px' }} />
                                </div>


                            </div>
                        </div>
                        <p className='caption'
                           style={{ textAlign: 'center', margin: '8px 0 50px' }}>注：具体行程内容会根据营员情况调整</p>
                    </section>

                    <section>
                        <h4 className='section-subtitle' style={{ marginTop: 24 }}>初级课程内容安排</h4>

                        <div className={classes.shadow}
                             style={{ maxWidth: 680, margin: ' 40px auto 0', borderRadius: 20, overflow: 'hidden' }}>
                            <div className=' text-center' style={{ padding: '14px 30px 0 30px' }}>
                                <div><b>营前准备</b></div>
                                <div style={{ fontSize: 13 }}>
                                    赴美签证面签预约及指导 & 学员基础知识问卷考核
                                </div>
                                <hr />
                            </div>
                            <div className={classes.timeLineContainer}>

                                <div style={{ flex: 1, paddingLeft: 30 }}>
                                    {timeLines.slice(0, 7).map((line, index) => {
                                        return <div key={index} className={classes.timeLine}>
                                            <h5 className='section-subtitle2'>{line.title}</h5>
                                            <div>
                                                {
                                                    line.content.split('\n').map((str, index) => {
                                                        return <p key={index} className='content'>
                                                            {str}
                                                        </p>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    })}
                                </div>

                                <div style={{ flex: 1, paddingLeft: 30 }}>
                                    {timeLines.slice(7).map((line, index) => {
                                        return <div key={index} className={classes.timeLine}>
                                            <h5 className='section-subtitle2'>{line.title}</h5>
                                            <div>
                                                {
                                                    line.content.split('\n').map((str, index) => {
                                                        return <p key={index} className='content'>
                                                            {str}
                                                        </p>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    })}
                                </div>

                            </div>

                            <div className='text-center' style={{ padding: '0 30px 12px 30px' }}>
                                <hr />
                                <div><b>第15天 离营日</b></div>
                                <div style={{ fontSize: 14 }}>离营並返回国内</div>
                            </div>
                        </div>
                    </section>

                    <div className={classes.font3} style={{ marginTop: 38, }}>
                        <h6><b>初级课程 开营时间</b></h6>
                        <p className='content'>
                            06月17日 - 06月28日<br />
                            06月24日 - 07月05日<br />
                            07月01日 - 07月12日<br />
                            07月08日 - 07月19日<br />
                            07月15日 - 07月26日<br />
                            07月22日 - 08月02日<br />
                            07月29日 - 08月09日<br />
                        </p>


                    </div>
                </div>}

                {this.state.tab === 1 && <div>
                    <p className='content text-indent'
                       style={{ maxWidth: 660, padding: '0 12px', margin: 'auto', marginTop: 52, }}>
                        课程包含了理论课程以及相应的实践课程，并且设计了详实的作业以及测试部分。在这门课程中，学员将会学习常见的各类算法，熟悉并灵活运用各种数据结构，完成100道算法题目，此外，我们还设计了贴近实际的项目来帮助你更快的上手编程。</p>

                    <section style={{ maxWidth: 760, margin: 'auto', marginTop: 56, }}>
                        <h4 className='section-subtitle'>七个课程阶段</h4>
                        <div className={classes.topicContainer}>
                            <div>
                                <div className={classes.topic}>
                                    <div className={classes.title}>
                                        <h6 className='section-subtitle2'>阶段一&nbsp;&nbsp;计算机导论与C语言基础</h6>
                                        {/*<img src='/icon2_1.png' alt='主题一' height='20' />*/}
                                    </div>
                                    <p className='content'>- 计算机的基本原理</p>
                                    <p className='content'>- 计算机的历史和未来</p>
                                    <p className='content'>- 程序运行的基本原理</p>
                                    <p className='content'>- 感性认识计算机程序</p>
                                    <p className='content'>- 从现实问题到计算机程序</p>
                                    <p className='content'>- 理性认识C程序 导论</p>
                                    <p className='content'>- 数据类型</p>
                                    <p className='content'>- 运算</p>
                                    <p className='content'>- 控制</p>
                                    <p className='content'>- 数组</p>
                                    <p className='content'>- 字符串</p>
                                    <p className='content'>- 课程测验</p>
                                    <hr />
                                </div>
                                <div className={classes.topic}>
                                    <div className={classes.title}>
                                        <h6 className='section-subtitle2'>阶段二&nbsp;&nbsp;C语言程序设计进阶 - 函数</h6>
                                        {/*<img src='/icon2_2.png' alt='主题二' height='20' />*/}
                                    </div>
                                    <p className='content'>- 递归</p>
                                    <p className='content'>- 指针(一)</p>
                                    <p className='content'>- 指针(二)</p>
                                    <p className='content'>- 指针(三)</p>
                                    <p className='content'>- 结构体与链表</p>
                                    <p className='content'>- “面向对象”的编程思想</p>
                                    <hr />
                                </div>

                                <div className={classes.topic}>

                                    <div className={classes.title}>
                                        <h6 className='section-subtitle2'>阶段三&nbsp;&nbsp;C++程序设计</h6>
                                    </div>
                                    <p className='content'>- 从C到C++</p>
                                    <p className='content'>- 类和对象</p>
                                    <p className='content'>- 运算符重载</p>
                                    <p className='content'>- 继承与派生</p>
                                    <p className='content'>- 多态与虚函数</p>
                                    <p className='content'>- 文件操作与模板</p>
                                    <p className='content'>- 标准模板库 STL-1</p>
                                    <p className='content'>- 标准模板库 STL-2</p>
                                    <p className='content'>- 课程测试</p>
                                    <hr />
                                </div>
                            </div>
                            <div>
                                <div className={classes.topic}>

                                    <div className={classes.title}>
                                        <h6 className='section-subtitle2'>阶段四&nbsp;&nbsp;算法基础 - 枚举</h6>
                                        {/*<img src='/icon2_3.png' alt='主题三' height='20' style={{ marginRight: 8 }} />*/}
                                    </div>
                                    <p className='content'>- 递归</p>
                                    <p className='content'>- 动态规划1</p>
                                    <p className='content'>- 动态规划2</p>
                                    <p className='content'>- 深度优先搜索1</p>
                                    <p className='content'>- 深度优先搜索2</p>
                                    <p className='content'>- 广度优先搜索</p>
                                    <p className='content'>- 二分法与贪心算法</p>
                                    <p className='content'>- 课程测试</p>
                                    <hr style={{ margin: '22px 0 20px' }} />

                                </div>

                                <div className={classes.topic}>
                                    <div className={classes.title}>
                                        <h6 className='section-subtitle2'>阶段五&nbsp;&nbsp;数据结构基础 - 线性表</h6>
                                        {/*<img src='/icon2_4.png' alt='主题四' height='20' />*/}
                                    </div>
                                    <p className='content'>- 栈与队列</p>
                                    <p className='content'>- 字符串</p>
                                    <p className='content'>- 二叉树基础</p>
                                    <p className='content'>- 二叉树应用</p>
                                    <p className='content'>- 树</p>
                                    <p className='content'>- 图</p>
                                    <p className='content'>- 课程测试</p>
                                    <hr style={{ margin: '22px 0 20px' }} />
                                </div>
                                <div className={classes.topic}>
                                    <div className={classes.title}>
                                        <h6 className='section-subtitle2'>阶段六&nbsp;&nbsp;高级数据结构与算法(Optional)</h6>
                                    </div>
                                    <p className='content'>- 内排序1</p>
                                    <p className='content'>- 内排序2</p>
                                    <p className='content'>- 外排序</p>
                                    <p className='content'>- 检索</p>
                                    <p className='content'>- 索引</p>
                                    <p className='content'>- 高级线性结构</p>
                                    <p className='content'>- 高级树形结构</p>
                                    <p className='content'>- 课程测试</p>
                                    <hr style={{ margin: '22px 0 20px' }} />
                                </div>

                                <div className={classes.topic}>
                                    <div className={classes.title}>
                                        <h6 className='section-subtitle2'>阶段七&nbsp;&nbsp;程序开发项目实践(Optional)</h6>
                                        {/*<img src='/icon2_5.png' alt='主题五' height='20' />*/}
                                    </div>
                                    {/*<p className='content'>- 美国历史与文化</p>*/}
                                    {/*<p className='content'>&nbsp;</p>*/}
                                    <hr style={{ margin: '30px 0 20px' }} />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section>
                        <h4 className='section-subtitle' style={{ marginTop: 64 }}>中級課程日程内容安排</h4>

                        <div className={classes.shadow}
                             style={{ maxWidth: 680, margin: ' 40px auto 0', borderRadius: 20, overflow: 'hidden' }}>
                            <div className=' text-center' style={{ padding: '14px 30px 0 30px' }}>
                                <div><b>营前准备</b></div>
                                <div style={{ fontSize: 13 }}>
                                    赴美签证面签预约及指导 & 学员基础知识问卷考核
                                </div>
                                <hr />
                            </div>
                            <div className={classes.timeLineContainer}>

                                <div style={{ flex: 1, paddingLeft: 30 }}>
                                    {timeLines2.slice(0, 14).map((line, index) => {
                                        return <div key={index} className={classes.timeLine}>
                                            <h5 className='section-subtitle2'>{line.title}</h5>
                                            <div>
                                                {
                                                    line.content.split('\n').map((str, index) => {
                                                        return <p key={index} className='content'>
                                                            {str}
                                                        </p>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    })}
                                </div>

                                <div style={{ flex: 1, paddingLeft: 30 }}>
                                    {timeLines2.slice(14).map((line, index) => {
                                        return <div key={index} className={classes.timeLine}>
                                            <h5 className='section-subtitle2'>{line.title}</h5>
                                            <div>
                                                {
                                                    line.content.split('\n').map((str, index) => {
                                                        return <p key={index} className='content'>
                                                            {str}
                                                        </p>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    })}
                                    <Hidden xsDown>
                                        <div />
                                    </Hidden>

                                </div>

                            </div>

                            <div className='text-center' style={{ padding: '0 30px 12px 30px' }}>
                                <hr />
                                <div><b>第28天 离营日</b></div>
                                <div style={{ fontSize: 14 }}>离营並返回国内</div>
                            </div>
                        </div>
                    </section>
                    <div className={classes.font3} style={{ marginTop: 38, }}>
                        <h6><b>中级课程 开营时间</b></h6>
                        <p className='content'>
                            06月17日 - 07月12日<br />
                            07月15日 - 08月09日<br />
                        </p>
                    </div>


                </div>}

                {this.state.tab === 2 && <div>
                    <p className='content text-center'
                       style={{ maxWidth: 660, padding: '0 12px', margin: 'auto', marginTop: 52, }}>
                        高级课程安排请咨询项目经理</p>

                </div>

                }
            </div>
        )
    }
}

export default withStyles(styles)(TimeLine)